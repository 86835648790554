// constants
import { apiRouter } from "@gdf/shared/src/constants/router";

// libraries
import { request } from "@gdf/shared/src/libraries";

/**
 * Crée une demande de contact.
 */
const contactMetricCreate = async ({ requestContext, body, parameters }) => {
  const route = apiRouter
    .findByName("Api.Action.Room.Metric.Create")
    .toFilled()
    .setParameters(parameters);

  return await request({
    url: route.generateUri().toString(),
    method: route.getMethods()[0],
    context: requestContext,
    type: "formData",
    body,
  });
};

export default contactMetricCreate;
