// constants
import { apiRouter } from "@gdf/shared/src/constants/router";

// libraries
import { request, get } from "@gdf/shared/src/libraries";

// normalizers
import { roomGetIndexesParseResponse } from "@gdf/resources/src/normalizers/room";

function roomMetricCreate({ requestContext }) {
  const route = apiRouter.findByName("Api.Rooms.Indexes").toFilled();

  return request({
    url: route.generateUri().toString(),
    method: route.getMethods()[0],
    context: requestContext,
  }).then((response) => {
    return {
      raw: response,
      parsed: roomGetIndexesParseResponse({
        rawResponse: get(response, "body"),
      }),
    };
  });
}

export default roomMetricCreate;
