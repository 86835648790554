// deps
import { useRef } from "react";
import useSWRInfinite from "swr/infinite";

// hooks
import { useFetcher } from "../hooks/fetcher";

// libraries
import { generateUri } from "@gdf/shared/src/libraries";

// constants
import { apiRouter } from "@gdf/shared/src/constants/router";
import { BOOKING_WORKFLOW_VALUE_NATIVE } from "../constants/booking";
import { BOOKING_SOURCE_VALUE_WEB } from "cloudspire-shared/constants/booking";

// normalizers
import { buildRoomParseShow, buildRoomParseSearch } from "../normalizers/room";
import { pricingEstimateParseSearch } from "../normalizers/pricing/estimate/parseSearch";

export function roomMessagePage({ referenceOrSlug, configuration }) {
  return {
    key: referenceOrSlug
      ? generateUri({
          router: apiRouter,
          name: "Api.Rooms.Action.Current",
          parameters: {
            referenceOrSlug,
          },
        })
      : null,
    normalizer: buildRoomParseShow({ configuration }),
  };
}

export function roomContactPage({ reference, configuration }) {
  return {
    key: reference
      ? generateUri({
          router: apiRouter,
          name: "Api.Rooms.Action.Current",
          parameters: {
            referenceOrSlug: reference,
          },
        })
      : null,
    normalizer: buildRoomParseShow({ configuration }),
  };
}

export function roomBookingPage({ roomReference, configuration }) {
  return {
    key: roomReference
      ? generateUri({
          router: apiRouter,
          name: "Api.Rooms.Action.Current",
          parameters: {
            referenceOrSlug: roomReference,
          },
        })
      : null,
    normalizer: buildRoomParseShow({ configuration }),
  };
}

export function roomRoomPage({ referenceOrSlug, checkin, configuration }) {
  return {
    key: generateUri({
      router: apiRouter,
      name: "Api.Rooms.Action.Current",
      parameters: {
        referenceOrSlug,
      },
      query: {
        metrics: "1",
        checkin,
      },
    }),
    normalizer: buildRoomParseShow({ configuration }),
  };
}

export function roomContentCampaignContainer({
  configuration,
  contentId,
  query,
}) {
  return {
    key: generateUri({
      router: apiRouter,
      name: "Api.Rooms",
      query: {
        ...query,
        sorts: "random_sort",
        content: contentId,
      },
    }),
    normalizer: buildRoomParseSearch({ configuration }),
  };
}

export function useInifiniteRoomListResponse(options) {
  const {
    options: swrOptions,
    perPage,
    query,
    parameterList,
    contentId,
    configuration,
  } = options;

  const fetcher = useFetcher();

  const staleReady = useRef(false);
  async function multiFetch(query) {
    const roomList = await fetcher(
      generateUri({
        router: apiRouter,
        name: "Api.Rooms",
        query,
      })
    );

    const flattenRoomList = [];
    for (const room of roomList.body?.data ?? []) {
      flattenRoomList.push(room);
      for (const roomChild of room?.childs ?? []) {
        flattenRoomList.push(roomChild);
      }
    }

    const priceList = await fetcher(
      generateUri({
        router: apiRouter,
        name: "Api.Alfred.Room.Pricing.Estimate",
        query: {
          room_id: flattenRoomList.map((room) => room.reference),
          channelId: configuration.channel.id,
          source: BOOKING_SOURCE_VALUE_WEB,
          workflow: BOOKING_WORKFLOW_VALUE_NATIVE,
          adults: parameterList?.capacity || 1,
          ...parameterList,
          capacity: undefined,
        },
      })
    );

    return {
      roomList: buildRoomParseSearch({ configuration })({
        data: roomList.body,
      }),
      priceList: pricingEstimateParseSearch({ data: priceList.body }),
      rawRoomList: roomList.body,
      rawPriceList: priceList.body,
    };
  }

  const response = useSWRInfinite(
    function getKey(pageIndex) {
      return {
        per_page: perPage,
        ...query,
        ...parameterList,
        content: contentId,
        page: pageIndex + 1,
      };
    },
    multiFetch,
    {
      revalidateIfStale: staleReady.current,
      revalidateOnFocus: false,
      dedupingInterval: 0,
      revalidateFirstPage: false,
      ...swrOptions,
    }
  );

  const data = {
    roomList: [],
    priceList: {},
    meta: {
      total:
        (response.data ?? []).slice(-1)?.[0]?.rawRoomList?.meta?.nb_results ??
        0,
    },
  };
  for (const item of response.data ?? []) {
    data.roomList.push(...(item.roomList?.data ?? []));
    Object.assign(data.priceList, item.priceList?.data);
  }

  return { ...response, rawData: response.data, data };
}

export { default as roomGetIndexes } from "./room/getIndexes";

export { default as roomMetricCreate } from "./room/metric/create";
